export const introDriverTips = {
  title: {
    pl: 'Warunki i instrukcja przewozu',
  },
  back: {
    pl: 'CARGONITE',
  },
  desc: {
    pl: '',
  },
}