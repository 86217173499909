import React from 'react'
import { Link } from 'gatsby'
import { useLangContext } from '../../../context/lang.context'
import { sContent } from '../../../style/style'
import ButtonOutline from '../../../components/ButtonOutline'
import { s, colors } from '../../../style'

const DriverContent = () => {
  const { lang } = useLangContext()

  return (
    <>
      <div css={[sContent, sContent2]}>
        <h2>
          Serdecznie dziękujemy za opiekę nad naszym ładunkiem i profesjonalne
          podejście.
        </h2>
        <p>
          Zapoznaj się proszę z poniższymi warunkami i instrukcją dot. tego
          przewozu.
        </p>

        <p>
          <span css={sText}><strong>Szczególnie weź pod uwagę listę miejsc, w których nie możesz się
          zatrzymywać!</strong></span>
          <br />
          Listę tych parkingów znajdziesz również w linku, w wiadomości SMS, którą
          otrzymałeś.
        </p>

        <p>
          W przypadku niedostosowania się do poniższych instrukcji będziemy
          traktować to jako złamanie procedur bezpieczeństwa dostawy, poddanie
          ładunku realnemu zagrożeniu oraz rażące niedbalstwo po stronie podmiotu,
          który przewozi towar i jest za niego odpowiedzialny.
        </p>

        <h3>Zasady postępowania z ładunkiem:</h3>
        <p>
          W przypadku, kiedy nie realizują Państwo bezpośredniego transportu lub
          wymagany jest postój obowiązkiem jest:
        </p>
        <ol>
          <li>
            <strong>zgłoszenie do zleceniodawcy:</strong>
            <ul>
              <li>adres parkingu</li>
              <li>koordynaty GPS parkingu</li>
            </ul>
          </li>
          <li>
            <strong>wybór min. dwóch opcji zabezpieczeń parkingu:</strong>
            <ul>
              <li>sprawnie działający monitoring</li>
              <li>oświetlenie terenu</li>
              <li>strażnik/budka strażnicza</li>
              <li>niepozostawianie towaru bez kontroli</li>
              <li>ogrodzenie parkingu ze wszystkich stron (teren ogrodzony)</li>
              <li>miejsce postojowe przypisane do danego pojazdu</li>
              <li>
                możliwość legitymowania osoby wyprowadzającej pojazd z parkingu
              </li>
              <li>
                właściwe usytuowanie pojazdu na parkingu ( ocena widoczności,
                otoczenia)
              </li>
            </ul>
          </li>
        </ol>
        <h3>Zasady bezpiecznego parkowania:</h3>
        <ul>
          <li>zabrania się nieplanowanych postojów</li>
          <li>zabrania się postoju w promieniu 200km od miejsca załadunku</li>
          <li>zabrania się postoju w promieniu 10 km od przejścia granicznego</li>
          <li>
            zabrania się przewożenia osób innych jak kierowca w kabinie pojazdu
          </li>
          <li>bezzwłoczne zgłaszanie wszelkich niepokojących sytuacji</li>
          <li>maksymalizacja możliwości bezpośredniej dostawy</li>
        </ul>
        <div css={sButtonWrapper}>
          <ButtonOutline link={`/pdf/lista_autohofow_w_niemczech.pdf`}>Lista białych parkingów</ButtonOutline>
        </div>
      </div>
    </>
  )
}

const sContent2 = {
  ul: {
    margin: '1rem 1rem 3rem',
    li: { 
      color: 'rgba(255, 255, 255, .6)',
      lineHeight: '2em',
      fontSize: '1.125rem',
    } 
  },
  ol: {
    margin: '1rem 1rem 3rem',
    li: { 
      color: 'rgba(255, 255, 255, .6)',
      lineHeight: '2em',
      fontSize: '1.125rem',
    },
    ul: {
      margin: 0,
    } 
  },
  a: { 
    color: colors.main, 
  },
  '.driverContentButton': {
    textDecoration: 'none',
  }
}

const sButtonWrapper = {
  display: 'flex',
  justifyContent: 'center',
  a: {
    textDecoration: 'none',
    fontWeight: 'normal'
  }
}

const sText = {
  textDecoration: 'underline',
}

export default DriverContent
