import React from 'react'
import Seo from '../../components/Seo'
import { useLangContext } from '../../context/lang.context'
import IntroSection from './IntroSection/IntroSection'
import { seo } from './seo'
import DriverContent from './DriverContent/DriverContent'

const Driver = () => {
  const { lang } = useLangContext()

  return (
    <>
      <Seo
        title={`Warunki i instrukcja przewozu`}
        description={``}
        lang={lang}
      />
      <IntroSection />
      <DriverContent />
    </>
  )
}

export default Driver
