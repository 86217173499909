import React from 'react'
import Layout from '../components/Layout'
import Driver from '../page/Driver/Driver'

const DriverPage = () => (
  <Layout>
    <Driver />
  </Layout>
)

export default DriverPage
